import logo from './logo.svg';
import './App.css';
import { Route, Routes, HashRouter} from 'react-router-dom';
import { lazy, Suspense } from 'react';

//components
import Home from './components/home/Home'
// import Global from './components/map/Global'
import NotFound from './components/ErrorPages/NotFound';
import SignupRoute from './components/auth/SignupRoute';


//components
import Profile from './components/profile/Profile';
import Transition from './deeplinks/Transition';
import Code from './qrs/Code';
import Empower from './deeplinks/Empower';

const Global = lazy(() => import('./components/map/Global'))

function App() {



  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/iway-apps/:appUrl/:element' element={<Transition />} />
          <Route exact path='/empower' element={<Empower />} />

          <Route exact path='/:venueName' element={<Suspense fallback={<h1>Loading...</h1>} ><Global /></Suspense>} />
          
          <Route exact path='/profile' element={<Profile />} />
          <Route path="/auth/*" element={<SignupRoute />} />
          <Route path="/qr/:code" element={<Code />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
      
    </div>
  );
}

export default App;
