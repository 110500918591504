import { Box, Typography, styles } from "@mui/material"
import { useRef, useState } from "react"

const categories = ["All", "Academic", "Hospital", "Tech Park", "Mall", "Event"]
const Categories = ({category, setCategory}) => {

    const ref = useRef()

    
    const handleChooseCategory = (elm) => {
        setCategory(elm)
        // ref.current.scrollLeft += 90

    }

    return (
        <Box style={{
            margin: "12px auto 0 16px",
        }}>
            <Box ref={ref} className="noScroll" style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 12,
                overflowX: 'auto'
            }}>
                {
                    categories.map(elm => (
                        <button onClick={() => handleChooseCategory(elm)} style={{
                           padding: category===elm ? 0 : '0px 10px',
                           borderBottom: category === elm ? '3px solid #24b9b0':'unset',
                           height: 48,
                           minWidth: 'max-content',
                           transition: 'padding 0.3s'
                        }}>
                            <Typography style={{
                                fontSize: 14,
                                fontWeight: 500,
                                lineHeight: '20px',
                                padding: '4px 6px',
                                borderRadius: 4,
                                textTransform: 'uppercase',
                                color: category === elm ? '#24B9B0' : "#000",
                                background: category === elm ? 'rgba(22, 21, 21, 0.05)':'unset',
                                transition: 'color background 0.3s'
                            }}>{elm}</Typography></button>
                    ))
                }
            </Box>

        </Box>
    )
}

export default Categories