import { createSlice } from '@reduxjs/toolkit'

export const VenueSlice = createSlice({
  name: 'venues',
  initialState: {value: []},
  reducers: {
    setVenuesDataReducer: (state, action) => {
      state.value = action.payload
    },
    addVenue: (state, action) => {
      if(state.value.filter(elm => elm._id === action.payload[0]._id).length > 0) return
      state.value = state.value.concat(action.payload)
    }
  }
})

export default VenueSlice.reducer
export const { setVenuesDataReducer, addVenue } = VenueSlice.actions