import { Box, Typography, styled, TextField, Backdrop, CircularProgress, InputAdornment } from "@mui/material"
import { useState } from "react"
import iconShow from '../../assets/Iconshow.svg'
import iconHide from '../../assets/Iconhide.svg'
import googleLogo from '../../assets/googleLogo.svg'
import { Link, useNavigate } from "react-router-dom"
import { signin } from "../../services/api"
import { isMobileNumberOrEmail } from "./utils"
import {GoogleLogin} from "react-google-login"

let baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOCAL_URL : process.env.REACT_APP_REMOTE_URL



const Component = styled(Box)({
    display: 'flex',
    
})
const LeftBx = styled(Box)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down("sm")]: {
        width: '0%'
    }
}))
const RightBx = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'flex-start',
        alignItems: 'flexStart',
    }
}))

const Input = styled(TextField)({
    width: 328,
    height: 58,
    borderRadius: 4,
    '& label.Mui-focused': {
        color: '#24b9b0',
    },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#24b9b0'
        }
    }
})
const HeadTxt = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'roboto',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '30px',
    textAlign: 'left',
    width: 328,
    [theme.breakpoints.down("sm")]: {
        marginTop: 60
    }
}))
const Section = styled(Box)({
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    alignItems: 'flex-start',
    justifyContent: 'center'

})
const ShowPassBtn = styled('button')({
    position: 'absolute',
    right: 8
})


const SigninBtn = styled('button')({
    width: 328,
    height: 48,
    borderRadius: 4,
    background: '#24b9b0',
    color: '#fff',
    fontSize: 16,
    lineHeight: '23px'
})
const ForgotText = styled(Link)({
    fontSize: 14,
    color: '#24b9b0',
    padding: 10,
    float: 'right',
    textDecoration: 'none'
    
})
const GoogleBtn = styled('button')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 328,
    height: 48,
    gap: 20,
    fontSize: 16,
    lineHeight: '25px',
    border: '1px solid #b3b3b3',
    borderRadius: 4
})
const Anc = styled(Link) ({
    color: '#24b9b0',
    textDecoration: 'none'
})
const CountryBx = styled(Box)({
    position: 'absolute',
    top: '50%',
    marginLeft: 20,
    transform: 'translateY(-50%)'
})


const initialCreds = {
    username: "",
    password: ""
}


const Signin = () => {

    const [showpass, setShowpass] = useState(false)
    const [creds, setCreds] = useState(initialCreds)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState()

    const navigate = useNavigate()

    const handleToggleShowPass = () => {
        setShowpass(showpass => !showpass)
    }

    const handleChange = (e) => {
        setCreds({
            ...creds,
            [e.target.name]: e.target.value
        })

        setType(isMobileNumberOrEmail(creds.username))
    }

    const handleSignup = async () => {
        setLoading(true)
        let post = {
            username: type === "mobile" ? "+91"+creds.username : creds.username,
            password: creds.password,
            // appId:"cms.iwayplus.rgcinavigation"
        }
        let response = await signin(post)
        setLoading(false)
        if(response.status === 200) {
            localStorage.setItem("accessToken", response.data.accessToken)
            localStorage.setItem("refreshToken", response.data.refreshToken)
            localStorage.setItem("userId", response.data.payload.userId)
            navigate('/')
        }
        else alert("Invalid username or password")
    }

    const googleAuth = () => {
        window.open(
            `https://dev.iwayplus.in/auth/google/callback`,
            "_self"
        )
    }





    return (
        <Component>

            <LeftBx>

            </LeftBx>
            <RightBx>
                <HeadTxt>Sign in</HeadTxt>

                <Section>
                    <Box style={{
                        position: 'relative',

                    }}>
                        {type === "mobile" && 
                            <CountryBx style={{
                                position: 'absolute',
                                left: 0
                            }}>
                                <Typography>IN +91</Typography>
                            </CountryBx>}
                        <Input 
                        InputProps={{
                                startAdornment: (
                                    <>
                                    {type === "mobile" && <InputAdornment 
                                            position="start"
                                            style={{
                                                marginLeft: "20%"
                                            }}
                                        />}
                                    </>
                                )
                            }} label="Email or mobile number" name="username" onChange={(e) => handleChange(e)} />
                        </Box>
                    <Box>
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative'
                    }}>
                        <Input label="Password" type={showpass ? "text":"password"} name="password" onChange={(e) => handleChange(e)} />
                        <ShowPassBtn onClick={() => handleToggleShowPass()}><img src={showpass ? iconShow : iconHide} /></ShowPassBtn>
                    </Box>
                        <ForgotText to={`/auth/forgot-password`}>Forgot Password ?</ForgotText>
                    </Box>

                    <SigninBtn alt="sign in" onClick={() => handleSignup()}>Sign in</SigninBtn>

                    <Typography style={{
                        color: '#8d8c8c',
                        fontSize: 16,
                        textAlign: 'center',
                        width: 328
                    }}>or</Typography>

                    <GoogleBtn onClick={() => googleAuth()}>
                        <img src={googleLogo} />
                        <Typography>Sign in with google</Typography>
                        
                    </GoogleBtn>
                    <Typography style={{
                        textAlign: 'center',
                        width: 328
                    }}>Don't have an account? <Anc to='/auth/signup'>Sign Up</Anc></Typography>

                    
                </Section>
                


            </RightBx>
            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>

        </Component>
    )
}

export default Signin