import numeric from 'numeric'

export const getDistance = (firstLocation, secondLocation) => {
    const earthRadius = 6371; // km //6378137; //m
    const diffLat = ((secondLocation.lat - firstLocation.lat) * Math.PI) / 180;
    const difflon = ((secondLocation.lng - firstLocation.lng) * Math.PI) / 180;
    // console.log("difflat,difflon",diffLat,difflon,firstLocation, secondLocation)
    const arc =
      Math.cos((firstLocation.lat * Math.PI) / 180) *
        Math.cos((secondLocation.lat * Math.PI) / 180) *
        Math.sin(difflon / 2) *
        Math.sin(difflon / 2) +
      Math.sin(diffLat / 2) * Math.sin(diffLat / 2);
    const line = 2 * Math.atan2(Math.sqrt(arc), Math.sqrt(1 - arc));
    const distance = earthRadius * line * 1000;
    return distance;
}

export const reverseNumericConverter = (n) => {
  return n=="ground" ? 0 :
  n=="first" ? 1 :
  n=="second" ? 2 :
  n=="third" ? 3 :
  n=="fourth" ? 4 :
  n=="fifth" ? 5 :
  n=="sixth" ? 6 :
  n=="seventh" ? 7 :
  n=="eighth" ? 8 :
  n=="nineth" ? 9 :
  n=="tenth" ? 10 :
  n=="eleventh" ? 11 :
  n=="twelvth" ? 12 :
  n=="thirteenth" ? 13 :
  n=="fourteenth" ? 14 :
  n=="fifteenth" ? 15:
  n=="sixteenth" ? 16 :
  n=="seventeenth" ? 17 :
  n=="eighteenth" ? 18 :
  n=="nineteenth" ? 19 :
  n=="twentieth" ? 20 :
  n=="base1" ? -1 :
  n=="base2" ? -2 :
  ""
}

export const numericConverter = (n) => {
  return n==0 ? "ground":
  n==1 ? "first":
  n==2 ? "second":
  n==3 ? "third":
  n==4 ? "fourth":
  n==5 ? "fifth":
  n==6 ? "sixth":
  n==7 ? "seventh":
  n==8 ? "eighth":
  n==9 ? "nineth":
  n==10 ? "tenth":
  n==11 ? "eleventh":
  n==12 ? "twelveth":
  n==13 ? "thirteenth":
  n==14 ? "fouteenth":
  n==15 ? "fifteenth":
  n==16 ? "sixteenth":
  n==17 ? "seventeenth":
  n==18 ? "eighteenth":
  n==19 ? "nineteenth":
  n==20 ? "twenteeth":
  n==-1 ? "base1":
  n==-2 ? "base2":
  ""
}

export const getHaversineDistance = (firstLocation, secondLocation) => {
  const earthRadius = 6371; // km //6378137; //m
  const diffLat = ((secondLocation.lat - firstLocation.lat) * Math.PI) / 180;
  const difflon = ((secondLocation.lng - firstLocation.lng) * Math.PI) / 180;
  // console.log("difflat,difflon",diffLat,difflon,firstLocation, secondLocation)
  const arc =
    Math.cos((firstLocation.lat * Math.PI) / 180) *
      Math.cos((secondLocation.lat * Math.PI) / 180) *
      Math.sin(difflon / 2) *
      Math.sin(difflon / 2) +
    Math.sin(diffLat / 2) * Math.sin(diffLat / 2);
  const line = 2 * Math.atan2(Math.sqrt(arc), Math.sqrt(1 - arc));
  const distance = earthRadius * line * 1000;
  return distance;
};

export const getNormalDistance = () => {

}

export const obtaincoordinates = (reference, vertical, horizontal) => {
  const R = 6378137; //Earth’s radius, sphere
  //Coordinate offsets in radians
  var dLat = vertical / R;
  var dLon = horizontal / (R * Math.cos((Math.PI * reference.lat) / 180));
  //OffsetPosition, decimal degrees
  var latA = reference.lat + (dLat * 180) / Math.PI;
  var lonA = reference.lng + (dLon * 180) / Math.PI;
  return { lat: latA, lng: lonA };
};

export const distance = (firstLocation, secondLocation) => {
  var dist =
    (secondLocation.localy - firstLocation.localy) ** 2 + (secondLocation.localx - firstLocation.localx) ** 2;
  return Math.sqrt(dist);
};

export const simplifyPath = (points, tolerance) => {
  // helper classes
  var Vector = function(x, y) {
    this.x = x;
    this.y = y;
  };
  var Line = function(p1, p2) {
    this.p1 = p1;
    this.p2 = p2; 
    this.distanceToPoint = function(point) {
      // slope
      var m = (this.p2.y - this.p1.y) / (this.p2.x - this.p1.x),
        // y offset
        b = this.p1.y - m * this.p1.x,
        d = [];
  if(this.p2.x==this.p1.x){
  d.push(point.x-p2.x);}
      // distance to the linear equation
  else{
      d.push(
        Math.abs(point.y - m * point.x - b) / Math.sqrt(Math.pow(m, 2) + 1)
      );}
      // distance to p1
      d.push(
        Math.sqrt(
          Math.pow(point.x - this.p1.x, 2) + Math.pow(point.y - this.p1.y, 2)
        )
      );
      // distance to p2
      d.push(
        Math.sqrt(
          Math.pow(point.x - this.p2.x, 2) + Math.pow(point.y - this.p2.y, 2)
        )
      );
      // return the smallest distance
      return d.sort(function(a, b) {
        return a - b; //causes an array to be sorted numerically and ascending
      })[0];
    };
    const intersectPoint = function(po1,po2) {
      var m = (this.p1.y-this.p2.y)/(this.p1.x - this.p2.x);
      var m1 = (po1.y-po2.y)/(po1.x-po2.x);
      var b = this.p1.y-m*this.p1.x;
      var b1 = po1.y - m1*po1.x;
      var x2=0;
      var y2 = 0;
      if(this.p1.x === this.p2.x){
        x2 = this.p1.x;
        y2 = m1*x2 + b1;  
      }else 
      if(po1.x === po2.x){
        x2 = po1.x;
        y2 = m*x2 + b;
       } else{
         x2 = (b1-b)/(m-m1);
         y2 = m*x2 + b;
       }
       return {x:Math.round(x2),y:Math.round(y2)};

    }
  };
  /**
   *
   * @param {*} points
   * @param {*} tolerance
   * @id N2.1.11
 * @author Gulshan Jangid
 * @description algorithm to generate smooth line
   */
  var douglasPeucker = function(points, tolerance) {
    if (points.length <= 2) {
      return [points[0]];
    }
    var returnPoints = [],
      // make line from start to end
      line = new Line(points[0], points[points.length - 1]),
      // find the largest distance from intermediate poitns to this line
      maxDistance = 0,
      maxDistanceIndex = 0,
      p;
    for (var i = 1; i <= points.length - 2; i++) {
      var distance = line.distanceToPoint(points[i]);
      if (distance > maxDistance) {
        maxDistance = distance;
        maxDistanceIndex = i;
      }
   }
    // check if the max distance is greater than our tollerance allows
    if (maxDistance >= tolerance) {
      p = points[maxDistanceIndex];
      line.distanceToPoint(p, true);
      // include this point in the output
      returnPoints = returnPoints.concat(
        douglasPeucker(points.slice(0, maxDistanceIndex + 1), tolerance)
      );
      // returnPoints.push( points[maxDistanceIndex] );
      returnPoints = returnPoints.concat(
        douglasPeucker(points.slice(maxDistanceIndex, points.length), tolerance)
      );
    } else {
      // ditching this point
      p = points[maxDistanceIndex];
      line.distanceToPoint(p, true);
      returnPoints = [points[0]];
    }
    return returnPoints;
  };
  var arr = douglasPeucker(points, tolerance);
  // always have to push the very last point on so it doesn't get left off
  arr.push(points[points.length - 1]);
  for(let i=0;i<arr.length-3;i++){
  //  var l1 = new Line(arr[i],arr[i+1]);
    //var l2 = new Line(arr[i+1],arr[i+2]);
   // var l3 = new Line(arr[i+2],arr[i+3]);
   // var dist = Math.sqrt((arr[i+1].x-arr[i+2].x)*(arr[i+1].x-arr[i+2].x) + (arr[i+1].y-arr[i+2].y)*(arr[i+1].y-arr[i+2].y));
   // if(dist<10){
      //find intersecting point of l1 and l3
    //   var point = l1.intersectPoint(arr[i+2],arr[i+3]);
     // arr.splice(i+1,2,point);
     // console.log(point);
      
    //}
  }
  return arr;
}

export const commonElements = (a, b) => {
   

  let common = []
  
  for(let i=0;i<a.length;i++) {
      for(let j=0;j<b.length;j++) {
          if(a[i].name === b[j].name) {
              //console.log(a[i], b[i])
              common.push({
                  name: a[i].name,
                  distance: a[i].distance+b[j].distance
              })
          }
          else {
              //console.log(a[i].name, "")
          }
      }
  }
  return common
}

export const convertSecondsToTime = (seconds) => {
  if(!seconds) return ""
  if (seconds < 0) {
    return "Please provide a non-negative value for seconds.";
  }

  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours == 0 ? "" : hours < 10 ? `0${hours} hr` : `${hours} hr`;
  const formattedMinutes = remainingMinutes == 0 ? "" : remainingMinutes < 10 ? `0${remainingMinutes} min` : `${remainingMinutes} min`;
  const formattedSeconds = remainingSeconds == 0 ? "" : remainingSeconds < 10 ? `0${remainingSeconds} sec` : `${remainingSeconds} sec`;

  return `${formattedHours} ${formattedMinutes} ${formattedSeconds}`
}

export const convertMetersToKilometers = (meters) => {
  if(!meters) return ""
  if (meters < 0) {
    return "Please provide a non-negative value for meters.";
  }

  const kilometers = meters / 1000;
  const formattedKilometers = kilometers.toFixed(1);

  return `${formattedKilometers} km`;
}

export const calculateDistance = (coord1, coord2) => {
  // Haversine formula to calculate the distance between two coordinates on the Earth's surface
  const earthRadius = 6371; // Radius of the Earth in kilometers
  const lat1 = coord1[0];
  const lon1 = coord1[1];
  const lat2 = coord2[0];
  const lon2 = coord2[1];

  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
    Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance;
}

export const shiftCoordinates = (globalCoordinates, orientationDegrees, shiftDistanceFeet) => {
  const orientationRadians = (orientationDegrees * Math.PI) / 180;

  // Destructure latitude and longitude
  const [latitude, longitude] = globalCoordinates;

  // Convert shift distance from feet to degrees (assuming Earth's radius is approximately 3,963 miles)
  const shiftDistanceDegrees = (shiftDistanceFeet / 5280) * (180 / (Math.PI * 3963));

  // Calculate new coordinates
  const newLatitude = latitude + shiftDistanceDegrees * Math.cos(orientationRadians);
  const newLongitude = longitude + shiftDistanceDegrees * Math.sin(orientationRadians);

  return [newLatitude, newLongitude];

}


export const getAngleFromNorth = (lat1, lon1, lat2, lon2) => {
  const dLon = lon2 - lon1;
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  const bearing = Math.atan2(y, x);
  const angleFromNorth = (bearing * 180) / Math.PI;
  return (angleFromNorth + 360) % 360;
  }

export const globalToLocal = (globalRefs, localRefs, targetLat, targetLon) => {
    if (globalRefs.length !== 4 || localRefs.length !== 4) {
      throw new Error('Exactly 4 global and local reference points are required.');
    }
  
    // Calculate transformation matrix
    const matrix = calculateTransformationMatrix(globalRefs, localRefs);
  
    // Convert target latitude and longitude to local coordinates
    const targetPoint = convertLatLonToLocal(targetLat, targetLon, matrix);
  
    return targetPoint;
  }
  
  function calculateTransformationMatrix(globalRefs, localRefs) {
    // Construct matrix A
    const A = globalRefs.map(({ lat, lon }) => [
      Math.cos(lat) * Math.cos(lon),
      Math.cos(lat) * Math.sin(lon),
      Math.sin(lat)
    ]);
  
    // Construct matrix B
    const B = localRefs.flatMap(({ x, y }) => [x, y]);
  
    // Solve for matrix X in AX = B
    const X = numeric.solve(A, B);
  
    // Create the transformation matrix
    const matrix = [
      [X[0], X[1], X[2]],
      [X[3], X[4], X[5]],
      [0, 0, 1]
    ];
  
    return matrix;
  }
  
  function convertLatLonToLocal(lat, lon, matrix) {
    // Convert latitude and longitude to radians
    const latRad = lat * (Math.PI / 180);
    const lonRad = lon * (Math.PI / 180);
  
    // Apply the transformation matrix
    const x = matrix[0][0] * Math.cos(latRad) * Math.cos(lonRad) +
              matrix[1][0] * Math.cos(latRad) * Math.sin(lonRad) +
              matrix[2][0] * Math.sin(latRad);
  
    const y = matrix[0][1] * Math.cos(latRad) * Math.cos(lonRad) +
              matrix[1][1] * Math.cos(latRad) * Math.sin(lonRad) +
              matrix[2][1] * Math.sin(latRad);
  
    // The third element of the resulting vector is ignored as it corresponds to the third column of the matrix
  
    return { x, y };
  }
  
  // Example usage:
  // const globalReferences = [
  //   { lat: 37.7749, lon: -122.4194 },
  //   { lat: 37.7749, lon: -122.4194 },
  //   { lat: 37.7749, lon: -122.4194 },
  //   { lat: 37.7749, lon: -122.4194 }
  // ];
  
  // const localReferences = [
  //   { x: 0, y: 0 },
  //   { x: 10, y: 0 },
  //   { x: 0, y: 10 },
  //   { x: 10, y: 10 }
  // ];
  
  // const targetLat = 37.7750;
  // const targetLon = -122.4195;
  
  // const localPoint = globalToLocal(globalReferences, localReferences, targetLat, targetLon);
  // console.log(localPoint);
  
export const calculateWalkingTime = (distanceFeet, walkingSpeedFeetPerSecond) => {
  if (distanceFeet < 0 || walkingSpeedFeetPerSecond <= 0) {
    throw new Error('Distance and walking speed must be positive values.');
  }

  const walkingTimeSeconds = distanceFeet / walkingSpeedFeetPerSecond;

  // Convert walking time to minutes and seconds
  let minutes = Math.floor(walkingTimeSeconds / 60);
  let seconds = Math.round(walkingTimeSeconds % 60);

  if(seconds > 10) minutes++


  return `${minutes} min`
}

export const getEta = (distanceFeet, walkingSpeedFeetPerSecond) => {
  if (distanceFeet < 0 || walkingSpeedFeetPerSecond <= 0) {
    throw new Error('Distance and walking speed must be positive values.');
  }

  const walkingTimeSeconds = distanceFeet / walkingSpeedFeetPerSecond;

  // Convert walking time to minutes and seconds
  let minutes = Math.floor(walkingTimeSeconds / 60);
  let seconds = Math.round(walkingTimeSeconds % 60);

  if(seconds > 10) minutes++


  var now = new Date();

  // Add minutes and seconds to current time
  now.setMinutes(now.getMinutes() + minutes);
  now.setSeconds(now.getSeconds() + seconds);

  var futureHours = now.getHours();
  var futureMinutes = now.getMinutes();

  // Convert hours to 12-hour format
  var ampm = futureHours >= 12 ? 'PM' : 'AM';
  futureHours = futureHours % 12;
  futureHours = futureHours ? futureHours : 12; // handle midnight

  // Format the time as "hh:mm AM/PM"
  var futureTime = futureHours + ':' + (futureMinutes < 10 ? '0' : '') + futureMinutes + ' ' + ampm;



  return futureTime

  
}

// Example usage:
//const distanceFeet = 1000; // Replace with your desired distance in feet
//const walkingSpeedFeetPerSecond = 4.55; // Average walking speed in feet per second

export const localToGlobalCoords = (x, y, cord_data, diff, floor) => {

  let ref=[]

  ref[0] = { "lat": parseFloat(cord_data['coordinates'][2]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][2]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][2]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][2]['localRef']['lat']) } 
  ref[1] = { "lat": parseFloat(cord_data['coordinates'][1]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][1]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][1]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][1]['localRef']['lat']) } 
  ref[2] = { "lat": parseFloat(cord_data['coordinates'][0]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][0]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][0]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][0]['localRef']['lat']) } 
  ref[3] = { "lat": parseFloat(cord_data['coordinates'][3]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][3]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][3]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][3]['localRef']['lat']) } 
  
var least_lat = 0;
  for (let i = 0; i < ref.length; i++) {
    if (ref[i].lat == ref[least_lat].lat) { //handling two points with equal lat
      if (ref[i].lng > ref[least_lat].lng) { least_lat = i; }
    }
    else if (ref[i].lat < ref[least_lat].lat) { least_lat = i; }
  }

    //evaluating high_lon
  var c1 = (least_lat == 3) ? 0 : (least_lat + 1)
  var c2 = (least_lat == 0) ? 3 : (least_lat - 1)
  var high_lon = (ref[c1].lng > ref[c2].lng) ? c1 : c2

  

  //lengths between given 4 global co-ordinates
  var lengths = []
  for (let i = 0; i < ref.length; i++) {
      var temp1;
      if (i == ref.length - 1) { temp1 = getHaversineDistance(ref[i], ref[0]); }
      else { temp1 = getHaversineDistance(ref[i], ref[i + 1]); }
    //  console.log(temp1*3.28084); //uncomment to print
      lengths.push(temp1);
  }

  var b = getHaversineDistance(ref[least_lat], ref[high_lon])
  const horizontal = obtaincoordinates(ref[least_lat], 0, b)
  var c = getHaversineDistance(ref[least_lat], horizontal);
  var a = getHaversineDistance(ref[high_lon], horizontal);
  var out = Math.acos((b * b + c * c - a * a) / (2 * b * c)) * 180 / Math.PI;

  var local_ref = {"localx": 0 ,"localy":0 } 
  if (diff && diff.length > 1) { //vertical correction across floors
    var test = diff.filter((x) => { return ((x.floor == floor)); });
    if(test.length > 0) {
        local_ref.localx = x - test[0].x
        local_ref.localy = y - test[0].y
    }
    else {
        local_ref.localx = x
        local_ref.localy = y
    }
  }
else {
    local_ref.localx = x
    local_ref.localy = y
}

  var l = distance(ref[least_lat], ref[high_lon]);
      var m = distance(local_ref, ref[high_lon]);
      var n = distance(ref[least_lat], local_ref);
      var theta = Math.acos((l * l + n * n - m * m) / (2 * l * n)) * 180 / Math.PI;
      if ((((l * l + n * n - m * m) / (2 * l * n)) > 1) || m == 0 || n == 0) { theta = 0; } //staright line case
      let ang = theta + out;

      
      
      var dist = distance(ref[least_lat], local_ref) * 0.3048; //to convert to meter
      var ver = dist * Math.sin(ang * Math.PI / 180.0);
      var hor = dist * Math.cos(ang * Math.PI / 180.0);
  
      var final = obtaincoordinates(ref[least_lat], ver, hor)
      return final
}

export const findMaxDistanceCoordinates = (coordinates) => {
  if (coordinates.length < 2) {
    return "There must be at least two coordinates to calculate distance.";
  }

  let maxDistance = -1;
  let maxCoord1, maxCoord2;

  for (let i = 0; i < coordinates.length; i++) {
    for (let j = i + 1; j < coordinates.length; j++) {
      const distance = calculateDistance(coordinates[i], coordinates[j]);
      if (distance > maxDistance) {
        maxDistance = distance;
        maxCoord1 = coordinates[i];
        maxCoord2 = coordinates[j];
      }
    }
  }

  return [maxCoord1, maxCoord2];
}

export const calculateBoundingCoordinates = (latitude, longitude, distance) => {
  // Earth's radius in kilometers
  const earthRadius = 6371;

  // Convert distance to radians
  const distanceRadians = distance / earthRadius;

  // Convert latitude and longitude to radians
  const latitudeRadians = (Math.PI / 180) * latitude;
  const longitudeRadians = (Math.PI / 180) * longitude;

  // Calculate angular distance in radians for latitude and longitude
  const deltaLatitude = distanceRadians * (180 / Math.PI);
  const deltaLongitude = distanceRadians * (180 / Math.PI) / Math.cos(latitudeRadians);

  // Calculate southwest and northeast points
  const southwestLatitude = latitude - deltaLatitude;
  const southwestLongitude = longitude - deltaLongitude;
  const northeastLatitude = latitude + deltaLatitude;
  const northeastLongitude = longitude + deltaLongitude;

  return {
    southwest: { latitude: southwestLatitude, longitude: southwestLongitude },
    northeast: { latitude: northeastLatitude, longitude: northeastLongitude }
  };
}

// Function to calculate a square around a point with a given distance in meters
export const findSquare = (latitude, longitude, distanceInMeters) => {
  // Earth's radius in meters
  const earthRadius = 6378137; // Approximately

  // Convert distance from meters to radians
  const deltaLat = (distanceInMeters / earthRadius) * (180 / Math.PI);
  const deltaLon = (distanceInMeters / (earthRadius * Math.cos((Math.PI / 180) * latitude))) * (180 / Math.PI);

  // Calculate square coordinates
  const topLeft = [
      latitude + deltaLat,
      longitude - deltaLon
    ]
  const topRight = [
      latitude + deltaLat,
      longitude + deltaLon
    ]
  const bottomLeft = [
      latitude - deltaLat,
      longitude - deltaLon
    ]
  const bottomRight = [
      latitude - deltaLat,
      longitude + deltaLon
    ]

  return [
      topLeft,
      topRight,
      bottomRight,
      bottomLeft
  ]
}
export const pointInsidePolygon = (point, polygon) => {
  let x = point[0], y = point[1];
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i][0], yi = polygon[i][1];
      let xj = polygon[j][0], yj = polygon[j][1];

      let intersect = ((yi > y) != (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  return inside;
}

function circleIntersection(circle1, circle2, circle3) {
  // Extract parameters for each circle
  const { localx: x1, localy: y1, distance: r1 } = circle1;
  const { localx: x2, localy: y2, distance: r2 } = circle2;
  const { localx: x3, localy: y3, distance: r3 } = circle3;

  // Calculate distances between centers of circles
  const d12 = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  const d23 = Math.sqrt(Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2));
  const d31 = Math.sqrt(Math.pow(x1 - x3, 2) + Math.pow(y1 - y3, 2));

  // Check if any circle is fully contained within another circle
  if (d12 < r1 + r2 && d23 < r2 + r3 && d31 < r3 + r1) {
      console.log("Circles are fully contained within each other. Infinite intersections.");
      return;
  }

  // Check for no intersection cases
  if (d12 > r1 + r2 && d23 > r2 + r3 && d31 > r3 + r1) {
      console.log("Circles do not intersect.");
      return;
  }

  // Calculate intersection points
  const A = 2 * (x2 - x1);
  const B = 2 * (y2 - y1);
  const C = Math.pow(r1, 2) - Math.pow(r2, 2) - Math.pow(x1, 2) + Math.pow(x2, 2) - Math.pow(y1, 2) + Math.pow(y2, 2);
  const D = 2 * (x3 - x2);
  const E = 2 * (y3 - y2);
  const F = Math.pow(r2, 2) - Math.pow(r3, 2) - Math.pow(x2, 2) + Math.pow(x3, 2) - Math.pow(y2, 2) + Math.pow(y3, 2);

  // Calculate intersection coordinates
  const x = (C * E - F * B) / (E * A - B * D);
  const y = (C * D - A * F) / (B * D - A * E);

  return {x, y}
}

// Example usage
// const circle1 = { localx: 0, localy: 0, distance: 3 };
// const circle2 = { localx: 4, localy: 0, distance: 3 };
// const circle3 = { localx: 2, localy: 5, distance: 3 };

// circleIntersection(circle1, circle2, circle3);

export const globalToLocalPoints = (cord_data, lat, lng) => {
  let ref=[]

  ref[0] = { "lat": parseFloat(cord_data['coordinates'][2]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][2]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][2]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][2]['localRef']['lat']) } 
  ref[1] = { "lat": parseFloat(cord_data['coordinates'][1]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][1]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][1]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][1]['localRef']['lat']) } 
  ref[2] = { "lat": parseFloat(cord_data['coordinates'][0]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][0]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][0]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][0]['localRef']['lat']) } 
  ref[3] = { "lat": parseFloat(cord_data['coordinates'][3]['globalRef']['lat']), "lng": parseFloat(cord_data['coordinates'][3]['globalRef']['lng']), localx: parseFloat(cord_data['coordinates'][3]['localRef']['lng']) ,localy: parseFloat(cord_data['coordinates'][3]['localRef']['lat']) } 
  
  let d1 = parseInt(getHaversineDistance(ref[0], { lat, lng })*3)
  let d2 = parseInt(getHaversineDistance(ref[1], { lat, lng })*3)
  let d3 = parseInt(getHaversineDistance(ref[2], { lat, lng })*3)

  const circle1 = { localx: ref[0].localx, localy: ref[0].localy, distance: d1 }
  const circle2 = { localx: ref[1].localx, localy: ref[1].localy, distance: d2 }
  const circle3 = { localx: ref[2].localx, localy: ref[2].localy, distance: d3 }

  return circleIntersection(circle1, circle2, circle3)

}

export const getDiff = (landmarks) => {
  var diffArr = []; //array to store offset of each floor
  if(landmarks.length === 0) return diffArr

  var ground_lift = landmarks.filter((x) => { return ((x.element.subType == 'lift') && (x.floor == 0)); });
  ground_lift = ground_lift.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
  if (ground_lift.length != 0) {
    var nth_lift = 0 //loop to find which lift is across more floors

    for (let i = 0; i < ground_lift.length; i++) {
      var temp = landmarks.filter((x) => { return ((x.element.subType == 'lift') && (x.name == ground_lift[i].name)); });

      if (temp.length >= landmarks.filter((x) => { return ((x.element.subType == 'lift') && (x.name == ground_lift[nth_lift].name)); }).length) {
        nth_lift = i
      }
    }

    // nth_lift = 2
    console.log(ground_lift[nth_lift])
    var lifts = landmarks.filter((x) => { return ((x.element.subType == 'lift') && (x.name == ground_lift[nth_lift].name)); });
    for (let i = 0; i < lifts.length; i++) {
      var temp = {};
            temp.x = lifts[i].coordinateX - ground_lift[nth_lift].coordinateX
            temp.y = lifts[i].coordinateY - ground_lift[nth_lift].coordinateY
            temp.floor = lifts[i].floor
            diffArr.push(temp)
    }
  }
  console.log(diffArr)
  return diffArr
}




