import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { getLandmarkByQr } from "../services/api"
import { styled } from '@mui/material'

import call from '../assets/call.svg'
import mail from '../assets/mail.svg'
import web from '../assets/web.svg'
import { clockTransform, runningStatus } from "./constants"
import Shimmer from "./Shimmer"
import { useSpeechSynthesis } from 'react-speech-kit';


const Contacts = styled('div')({
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    alignItems: 'flex-start'
})

const Contact = styled('div')({
    display: 'flex',
    gap: 18,
    alignItems: 'flex-start'
})

const ContactWrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
})

const Component = styled('div')({
    padding: 24,
    width: '100%',
    boxSizing: 'border-box'
})
const TitleBx = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 6
})
const Name = styled('p')({
    fontSize: 33,
    margin: 0,
    fontWeight: 500,
    lineHeight: '32px'
})
const Floor = styled('p')({
    color: '#777',
    fontSize: 24,
    lineHeight: '24px',
    fontWeight: 400,
    margin: 0
})
const Venue = styled('p')({
    color: '#777',
    fontSize: 21,
    fontWeight: 400,
    lineHeight: '20px',
    margin: 0
})
const Label = styled('p')({
    color: '#6C757D',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '16px',
    margin: 0
})
const Value = styled('a')({
    color: '#065AA5',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0
})

const TimeStamp = styled('p')({
    color: '#777',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0
})
const Footer = styled('div')({
    background: '#FFF',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    padding: 24,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0
})
const ContinueBtn = styled('button')({
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 27,
    background: '#6CC8BF',
    height: 36,
    width: '100%',
    color: '#FFF',
    fontSize: 21,
    fontWeight: 500,
    lineHeight: '140%' /* 19.6px */
})


const Code = () => {

    const { code } = useParams()
    const { speak, voices } = useSpeechSynthesis();
    const [spoken, setSpoken] = useState(false)

    const nameRef = useRef()

    

    const [landmark, setLandmark] = useState()

    useEffect(() => {
        fetchData()
    }, [])

   

   

   

   

    const fetchData = async () => {
        let response = await getLandmarkByQr(code)
        setLandmark(response.data)

    }

    const handleClickApp = () => {
        if(landmark.building_ID === "66ffc655cc539c610889cb98") {
            window.location.href = 
            `/#/iway-apps/empower.com/landmark?bid=
            ${landmark?.building_ID}&landmark=${landmark?.properties.polyId || landmark._id}
            &appStore=empower-2024/id6736653512&playStore=com.iwayplus.empower24`
        }
        else {
            window.location.href = 
            `/#/iway-apps/ashoka.com/landmark?bid=
            ${landmark?.building_ID}&landmark=${landmark?.properties.polyId || landmark._id}
            &appStore=accessible-ashoka/id6553976574&playStore=com.iwayplus.accessibleashoka`
        }
        
    }

 


    if(!landmark) return <Shimmer />
    if(!landmark?.name || landmark?.message === "qr cannot be found" ) return <h2>Oops! Cannot Find Any landmark for the qr {code} </h2>

    return (
        <div>
        <Component>
            <TitleBx>
                <ContinueBtn style={{ cursor: 'unset' }} ref={nameRef}  >{landmark?.name}</ContinueBtn>
                {/* <button style={{ float: 'right', cursor: 'pointer' }} onClick={() => speak({ text: `You are at ${landmark.name} on Floor ${landmark.floor}`, voice: voices[0] })}>🔊</button> */}
                <Floor>Floor {landmark?.floor}, {landmark?.buildingName}</Floor>
                <Venue>{landmark?.venueName}</Venue>
                {
                    landmark?.properties?.startTime &&
                    landmark?.properties?.endTime &&
                <TimeStamp>
                    {clockTransform(landmark?.properties?.startTime)} - {clockTransform(landmark?.properties?.endTime)}
                    <span style={{
                        color: runningStatus(landmark?.properties?.startTime, landmark?.properties?.endTime) === true ? "#4CAF50" : "#f00"
                    }}>{runningStatus(landmark?.properties?.startTime, landmark?.properties?.endTime) === true ? " Open Now":" Closed"}</span>
                </TimeStamp>}
            </TitleBx>
            {
                (
                    landmark?.properties?.contactNo || 
                    landmark?.properties?.phone ||
                    landmark?.properties?.email ||
                    landmark?.properties?.url || 
                    landmark?.building?.website
                ) &&
                <Contacts>
                <h3>Contact Details</h3>
                {(landmark?.properties?.contactNo || landmark?.properties?.phone) && <Contact>
                    <img src={call} />
                    <ContactWrap>
                        <Label>Phone</Label>
                        <Value href={`tel:${landmark?.properties?.contactNo || landmark?.building?.phone}`}>
                            {landmark?.properties?.contactNo || landmark?.properties?.phone}
                        </Value>
                    </ContactWrap>
                </Contact>}
                {landmark?.properties?.email && <Contact>
                    <img src={mail} />
                    <ContactWrap>
                        <Label>E-Mail</Label>
                        <Value href={`mailto:${landmark?.properties?.email}`}>
                            {landmark?.properties?.email}
                        </Value>
                    </ContactWrap>
                </Contact>}
               {(landmark?.properties?.url || landmark?.building?.website) && <Contact>
                    <img src={web} />
                    <ContactWrap>
                        <Label>Website</Label>
                        <Value target="_blank" href={landmark?.properties?.url || landmark?.building?.website}>
                            {landmark?.properties?.url || landmark?.building?.website}
                        </Value>
                    </ContactWrap>
                </Contact>}
                

            </Contacts>}
            {/* <div>
                photos
            </div> */}
            
        </Component>
        <Footer>
                <ContinueBtn onClick={handleClickApp}>
                    Continue to App
                </ContinueBtn>
        </Footer>
        </div>
    )
}

export default Code