import React, {useEffect} from 'react';

const appStore = "empower-2024/id6736653512"
const playStore = "com.iwayplus.empower24"

const Empower = () => {
    useEffect(() => {

        let appTimeout = setTimeout(() => {

            if(navigator.platform.includes("iPhone") || navigator.platform.includes("Mac")) window.location.assign(`https://apps.apple.com/in/app/${appStore}`)
            else window.location.assign(`https://play.google.com/store/apps/details?id=${playStore}`)

        }, 3000)

        return () => clearTimeout(appTimeout)

    }, [])

    return (
        <div >Opening App...</div>
    )
};

export default Empower;