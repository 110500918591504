import { createSlice } from '@reduxjs/toolkit'

export const PolylineSlice = createSlice({
  name: 'polyline',
  initialState: {value: []},
  reducers: {
    setPolylineDataReducer: (state, action) => {
      state.value = action.payload
    },
    addPolyline: (state, action) => {
      if(state.value.filter(elm => elm.building_ID === action.payload.building_ID).length > 0) return
      state.value.push(action.payload)
    }
  }
})

export default PolylineSlice.reducer
export const { setPolylineDataReducer, addPolyline } = PolylineSlice.actions