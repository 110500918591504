import { Box, styled, Typography } from "@mui/material"
import iwayLogo from '../../assets/iwayLogo.svg'
import search from '../../assets/search.svg'
import profileIcon from '../../assets/account_circle.svg'
import { useNavigate } from "react-router-dom"


const Header = () => {

    const navigate = useNavigate()

    const handleProfile = () => {
        navigate('/profile')
    }
    return (
        <Box style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 16px 0 16px'
        }}>
            <Box style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 12
            }}>
                <img src={iwayLogo} />
                <Typography style={{
                    fontSize: 24,
                    fontWeight: 700,
                    lineHeight: '30px'
                }}>Iwayplus</Typography>
            </Box>
            <Box style={{
                display: 'flex'
            }}>
                <button style={{
                padding: 4,
                border: '0.5px solid #ebebeb',
                height: 32,
                width: 32,
                borderRadius: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src={search} />
            </button>
            <button onClick={() => handleProfile()} >
                <img src={profileIcon} />

            </button>
            
            </Box>
            


        </Box>
    )
}

export default Header