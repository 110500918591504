import { Box, TextField, Typography, styled, InputAdornment } from "@mui/material"
import { useState } from "react"
import { isMobileNumberOrEmail } from "./utils"
import { useNavigate } from "react-router-dom"

//assets
import iconShow from '../../assets/Iconshow.svg'
import iconHide from '../../assets/Iconhide.svg'
import { resetPasswordUsingToken, sendOtpForAccountVerify } from "../../services/api"

const Component = styled(Box)({
    overflow: 'hidden'
})
const Header = styled(Box)({
    height: 48,
    width: '100%'
})
const Heading = styled(Typography)({
    fontFamily: 'roboto',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '30px',
    color: '#000'
})
const SubHead = styled(Typography)({
    color: '#242424',
    fontFamily: 'roboto',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '25px'
})
const Input = styled(TextField)({
    width: 328,
    height: 56,
       '& label.Mui-focused': {
        color: '#24b9b0',
    },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#24b9b0'
        }
    }
})
const CountryBx = styled(Box)({
    position: 'absolute',
    top: '50%',
    marginLeft: 20,
    transform: 'translateY(-6%)'
})
const VerifyBtn = styled('button')({
    width: 328,
    height: 48,
    borderRadius: 4,
    background: '#24b9b0',
    color: '#fff',
    fontSize: 16,
    lineHeight: '23px',
    marginTop: 32
})
const LeftBx = styled(Box)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down("sm")]: {
        width: '0%'
    }
}))
const RightBx = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'flex-start',
        alignItems: 'flexStart',
    }
}))
const PassBx = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
})
const ShowPassBtn = styled('button')({
    position: 'absolute',
    right: 8
})
const ErrTxt = styled(Typography)({
    textAlign: 'left',
    color: '#B3261E',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    margin: '4px 0 0 16px'
})
const PassSec = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 20
})

const initialPasswords = {
    password: "",
    confirmPassword: ""
}


const ChangePassword = ({ username, setUsername }) => {

    const [showpass, setShowpass] = useState({
        pass1: false,
        pass2: false
    })
    const [error, setError] = useState({
        match: true,
        length: true,
        filled: false
    })
    let type = isMobileNumberOrEmail(username.username)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleChange = (e) => {
        setUsername({
            ...username,
            [e.target.name]: e.target.value
        })
    }

    const handleToggleShowPass = (pass) => {
        setShowpass({...showpass, [pass]: !showpass[pass]})

    }

    const handleVerify = async () => {
        if(username.newPassword !== username.confirmPassword) {
            alert("password does not match")
        }
        setLoading(true)
        console.log(username)
        let response = await resetPasswordUsingToken({
            username: type==="mobile" ? "+91"+username.username:username.username,
            password: username.newPassword,
            token: username.token
        })
        setLoading(false)
        if(response.status === 200) {
            alert("Password is been reset successfully")
            navigate('/auth/signin')
        }
        else {
            alert("something went wrong")
            return
        }
   
        

    }
    return (
        <Component>
            <Header>
                
            </Header>
            <LeftBx></LeftBx>
            <RightBx>
            <Box style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                margin: '6px 18px 0 18px'
            }}>
                <Heading>Create New Password</Heading>
                <SubHead>Please create a new 4 character strong  password for your account. </SubHead>
            </Box>
            <Box style={{
                position: 'relative',
                width: 328,
                margin: '20px 16px 0 16px',
            }}>
               <PassSec>
                <PassBx>
                    <Input name="newPassword" type={showpass.pass1 ? "text":"password"} label="New Password" onChange={(e) => handleChange(e)} />
                    <ShowPassBtn onClick={() => handleToggleShowPass("pass1")}><img src={showpass.pass1 ? iconShow : iconHide} /></ShowPassBtn>
                </PassBx>
                <PassBx>
                    <Input name="confirmPassword" type={showpass.pass2 ? "text":"password"} label="Confirm Password" onChange={(e) => handleChange(e)} />
                    <ShowPassBtn onClick={() => handleToggleShowPass("pass2")}><img src={showpass.pass2 ? iconShow : iconHide} /></ShowPassBtn>
                    <Box>
                        {!error.match && <ErrTxt>Passwords don't match</ErrTxt>}
                        {!error.length && <ErrTxt>8 characters passwords required.</ErrTxt>}
                    </Box>
                </PassBx>
                </PassSec>
            </Box>

            <VerifyBtn alt="continue" onClick={() => handleVerify()}>Change Password</VerifyBtn>


            </RightBx>


        </Component>
    )
}

export default ChangePassword