export const deeplink_url = `https://iwayplus-workspace.slack.com/archives/D06UUQXQZCG/p1726830938260229`

export const clockTransform = (time) => {

    let hh = parseInt(time?.split(":")[0])
    let mm = time?.split(":")[1]

    let nhh = hh
    let meridian = "AM"

    if(hh == 0) nhh = 12
    else if(hh > 12) {
        nhh = hh-12
        meridian = "PM"
    }

    return `${nhh}:${mm} ${meridian}`

}

export const runningStatus = (startTime, endTime) => {
    let currentTime = new Date().toLocaleTimeString()
    let start = parseInt(startTime.split(":").join("")+"00")
    let end = parseInt(endTime.split(":").join("")+"00")

    let current = parseInt(currentTime.split(":").join(""))


    if(current > start && current < end) return true
    return false

}