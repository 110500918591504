import { createSlice } from '@reduxjs/toolkit'

export const BuildingSlice = createSlice({
  name: 'buildings',
  initialState: {value: []},
  reducers: {
    setBuildingDataReducer: (state, action) => {
      state.value = action.payload
    },
    addBuilding: (state, action) => {
      if(state.value.filter(elm => elm._id === action.payload._id).length > 0) return
      state.value = state.value.concat(action.payload)
    },
    pushBuildings: (state, action) => {
      // for(let i=0;i<action.payload.length;i++) {
      //   let containis = state.value.filter(elm => elm.id === actio)
      // }
    }
  }
})

export default BuildingSlice.reducer
export const { setBuildingDataReducer, addBuilding, pushBuildings } = BuildingSlice.actions