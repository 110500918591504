import { Box, Typography, styled, TextField, InputAdornment, Backdrop, CircularProgress } from "@mui/material"
import { useState } from "react"
import iconShow from '../../assets/Iconshow.svg'
import iconHide from '../../assets/Iconhide.svg'
import googleLogo from '../../assets/googleLogo.svg'
import { Link, useNavigate } from "react-router-dom"
import back from '../../assets/Back.svg'
import { getUsername, sendOtp, signup } from "../../services/api"
import { isMobileNumberOrEmail } from "./utils"
import GoogleLogin from "react-google-login"



const Component = styled(Box)({
    display: 'flex',
    position: 'relative'
    
})
const LeftBx = styled(Box)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down("sm")]: {
        width: '0%'
    }
}))
const RightBx = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'flex-start',
        alignItems: 'flexStart',
    }
}))

const Input = styled(TextField)({
    width: 328,
    height: 58,
    borderRadius: 4,
    '& label.Mui-focused': {
        color: '#24b9b0',
    },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#24b9b0'
        }
    }
})
const HeadTxt = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: 'roboto',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '30px',
    textAlign: 'left',
    width: 328,
    [theme.breakpoints.down("sm")]: {
        marginTop: 60
    }
}))
const Section = styled(Box)({
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    alignItems: 'flex-start',
    justifyContent: 'center'

})
const ShowPassBtn = styled('button')({
    position: 'absolute',
    right: 8
})

const initialCreds = {
    username: "",
    password: ""
}
const SignupBtn = styled('button')({
    width: 328,
    height: 48,
    borderRadius: 4,
    background: '#8d8c8c',
    color: '#fff',
    fontSize: 16,
    lineHeight: '23px'
})
const BackBtn = styled('button')({
    position: 'absolute',
    top: 12,
    left: 12
})
const PassBx = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
})
const PassSec = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 25
})
const CountryBx = styled(Box)({
    position: 'absolute',
    top: '50%',
    marginLeft: 20,
    transform: 'translateY(-50%)'
})
const ErrTxt = styled(Typography)({
    textAlign: 'left',
    color: '#B3261E',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    margin: '4px 0 0 16px'
})
const GoogleBtn = styled('button')({

})




const Signup = ({ user, setUser }) => {


    const navigate = useNavigate()

    const [showpass, setShowpass] = useState({
        pass1: false,
        pass2: false
    })
    const [type, setType] = useState()
    const [loading, setLoading] = useState(false)
    const [userExist, setUserExist] = useState(false)
    const [error, setError] = useState({
        match: true,
        length: true,
        filled: false
    })



    const handleToggleShowPass = (elm) => {
        setShowpass({...showpass, [elm]: !showpass[elm]})
    }

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })

        let usernameType = isMobileNumberOrEmail(user.username)
        setType(usernameType)
        if(user.name !== "" && user.username !== "" && user.password !== "" && user.confirmPassword !== "" ) {
            setError({
                ...error,
                ["filled"]: true
            })
        }
        else {
            setError({
                ...error,
                ["filled"]: false
            })
        }
    }

    const handleBack = () => {
        window.history.back()
    }

    const handleSignup = async () => {
        if(userExist) return
        if(user.password !== user.confirmPassword) {
            setError({ ...error, ["match"]: false })
            return
        }
        else setError({ ...error, ["match"]: true })

        if(user.password.length < 4) {
            setError({...error, ["length"]: false})
            return
        }
        else setError({ ...error, ["length"]: true })

        let post = {
            name: user.name,
            password: user.password,
            username: type === "mobile" ? "+91"+user.username : user.username,
            appId: "com.iwayplus.navigation"
        }

        setLoading(true)
        let response = await sendOtp(post)
        setLoading(false)

        if(response.status === 200) navigate('/auth/verify')
        else {
            alert("something went wrong")
        }
        
    }

    const responseGoogle = (response) => {
        console.log(response)
    }

    // const handleSearchUsername = async (e) => {
    //     let usernameStatus
    //     if(type === "mobile") {
    //         usernameStatus = await getUsername("+91"+e.target.value)
    //     }
    //     else usernameStatus = await getUsername(e.target.value)
    //     if(usernameStatus) setUserExist(usernameStatus.data.userExist)
    // }
    const handleSearchUsername = async (e) => {
        let usernameStatus;
        if (type === "mobile") {
            usernameStatus = await getUsername("+91" + e.target.value, "com.iwayplus.navigation"); 
        }
        else usernameStatus = await getUsername(e.target.value, "com.iwayplus.navigation"); 
        if (usernameStatus) setUserExist(usernameStatus.data.userExist);
    };



   

    return (
        <Component>
            <BackBtn onClick={() => handleBack()}><img src={back} /></BackBtn>

            <LeftBx>

            </LeftBx>
            <RightBx>
                <HeadTxt>Sign Up</HeadTxt>

                <Section>
                    <Box>
                    <Box style={{
                        position: 'relative',
                    }}>
                        {type === "mobile" && <CountryBx style={{
                            position: 'absolute',
                            left: 0
                        }}>
                            <Typography>IN +91</Typography>
                        </CountryBx>}
                        <Input 
                            InputProps={{
                                startAdornment: (
                                    <>
                                    {type === "mobile" && <InputAdornment 
                                            position="start"
                                            style={{
                                                marginLeft: "20%"
                                            }}
                                        />}
                                    </>
                                )
                            }}
                            label="Email or mobile number" name="username" 
                                onChange={(e) => {
                                    handleChange(e);
                                    handleSearchUsername(e)
                                }
                            } 
                            />
                      
                        </Box>
                        <ErrTxt style={{
                                display: userExist ? "block":"none"
                            }}>username is not available</ErrTxt>
                        </Box>
                    
                    <Input label="Name" name="name" onChange={(e) => handleChange(e)} />

                    <PassSec>
                        <PassBx>
                            <Input label="Password" type={showpass.pass1 ? "text":"password"} name="password" onChange={(e) => handleChange(e)} />
                            <ShowPassBtn onClick={() => handleToggleShowPass("pass1")}><img src={showpass.pass1 ? iconShow : iconHide} /></ShowPassBtn>
                        </PassBx>
                        <Box>
                            <PassBx>
                                <Input label="Confirm Password" type={showpass.pass2 ? "text":"password"} name="confirmPassword" onChange={(e) => handleChange(e)} />
                                <ShowPassBtn onClick={() => handleToggleShowPass("pass2")}><img src={showpass.pass2 ? iconShow : iconHide} /></ShowPassBtn>
                            </PassBx>
                            <Box>
                                {!error.match && <ErrTxt>Passwords don't match</ErrTxt>}
                                {!error.length && <ErrTxt>8 characters passwords required.</ErrTxt>}
                            </Box>
                        </Box>
                    </PassSec>

                    <SignupBtn style={{
                        background: error.filled ? '#24b9b0' : '#8d8c8c'
                    }} alt="sign up" title="sign up" onClick={() => handleSignup()} >Sign up</SignupBtn>

                    <GoogleBtn>
                        <GoogleLogin 
                            buttonText="Sign up with Google"
                            clientId={process.env.REACT_APP_GOOGLE_API_KEY}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                        />
                    </GoogleBtn>

                 

                    
                </Section>
                


            </RightBx>
            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>

        </Component>
    )
}

export default Signup