export const isMobileNumberOrEmail = (inputStr) => {
    // Regular expressions for mobile number (with country code) and email validation
    var mobileNumberRegex = /^\d{6,14}$/; // Assumes country code followed by 6-14 digits
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the input string matches mobile number or email regex
    if (mobileNumberRegex.test(inputStr)) {
        return "mobile";
    } else if (emailRegex.test(inputStr)) {
        return "email";
    } else {
        return null;
    }
}
