import { createSlice } from '@reduxjs/toolkit'

export const LiftSlice = createSlice({
  name: 'lifts',
  initialState: {value: []},
  reducers: {
    setLiftDataReducer: (state, action) => {
      state.value = action.payload
    },
    addLift: (state, action) => {
      if(state.value.filter(elm => elm.buildingId === action.payload.buildingId).length > 0) return
      state.value.push(action.payload)
    },
    editLift: (state, action) => {
      
      let index = -1
      state.value.forEach((elm, inx) => {
        if(elm.buildingId === action.payload.id) {
            index = inx
        }
      })

      state.value[index].selectedFloor = action.payload.selectedFloor
      state.value[index].floorValue = action.payload.floorValue

    }
  }
})

export default LiftSlice.reducer
export const { setLiftDataReducer, addLift, editLift } = LiftSlice.actions