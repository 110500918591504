import { Box, Typography, styled } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Dialog } from "@mui/material"
import { logout } from "../../services/api"
import { getUserDetails } from "../../services/api"
//assets
import avatarImg from '../../assets/UserImage.svg'
import accountImg from '../../assets/account_circle.svg'
import articleImg from '../../assets/article.svg'
import helpImg from '../../assets/help_center.svg'
import favouriteImg from '../../assets/favorite.svg'
import settingsImg from '../../assets/Vector.svg'

const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOCAL_URL : process.env.REACT_APP_REMOTE_URL


const NameBx = styled(Box)({
    padding: '12px 16px',
    boxSizing: 'border-box',
    height: 78,
    marginTop: 8,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    borderBottom: '1px solid #e5e5e5'
})
const Container = styled(Box)({
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8
})
const Option = styled(Box)({
    padding: '10px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12
})
const OptionLink = styled(Link)({
    textDecoration: 'none',
    color: '#181b1b',
    fontSize: 16,
    lineHeight: '25px'
})
const Component = styled(Box)({
    height: '100vh',
    position: 'relative',
    fontFamily: 'roboto'
})
const LogoutBtn = styled('button')({
    padding: '12px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#0B6B94',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '23px',
    border: '1px solid #b3b3b3',
    margin: 'auto 16px 20px 16px',
    borderRadius: 4,
    minWidth: 326,
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: 0
})
const CancelBtn = styled('button')({
    border: "1px solid #AA4A44",
    borderRadius: 5,
    color: '#AA4A44',
    padding: "8px 12px"
})

const ConfirmBtn = styled('button')({
    background: '#AA4A44',
    borderRadius: 5,
    color: '#fff'
})


const options = [
    {option: "Edit Profile", icon: accountImg},
    {option: "Favourite", icon: favouriteImg},
    {option: "Settings", icon: settingsImg},
    {option: "Terms and Privacy Policy", icon: articleImg},
    {option: "Help and Support", icon: helpImg}
]

const Profile = () => {

    const navigate = useNavigate()
    const [confirmation, setConfirmation] = useState(false)
    const [user, setUser] = useState()

    useEffect(() => {
        const fetchData = async () => {
            let response = await getUserDetails({userId: localStorage.getItem("userId")})
            if(response.status === 200) setUser(response.data)
        }

        fetchData()
    }, [])

    const handleOpen = () => {
        setConfirmation(true)
    }
    const handleClose = () => {
        setConfirmation(false)
    }

    const handleLogout = () => {
        handleOpen()
    }

    const handleConfirm = async () => {
        let response = await logout({ refreshToken: localStorage.getItem("refreshToken") })
        if(response.status) {
            localStorage.clear()
            alert("Logged in successfully")
            navigate('/')
        }
    }

    return (
        <Component>
            <Typography style={{
                padding: "16px 16px 0 16px",
                fontSize: 20,
                fontWeight: 700,
                lineHeight: '26px',
                textAlign: 'left',
                color: '#181b1b'
            }}>Account</Typography>
            <NameBx >

                <Box style={{
                    width: 54,
                    height: 54
                }}>
                    <img src={`` || avatarImg} />
                </Box>
                <Box style={{
                   textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4
                }}>
                    <Typography style={{
                        color: '#18181b',
                        fontWeight: 500,
                        lineHeight: "23px",
                        fontSize: 16

                    }}>{user?.name}</Typography>
                    <Typography style={{
                        fontSize: 12,
                        lineHeight: '18px'
                    }}>{user?.email || user?.mobile}</Typography>
                </Box>


            </NameBx>
            <Container>
                {
                    options?.map(elm => (
                        <Option>
                            <img src={elm.icon} />
                            <OptionLink>{elm.option}</OptionLink>
                        </Option>
                    ))
                }
            </Container>

            <LogoutBtn onClick={() => handleLogout()}>Log out</LogoutBtn>

            <Dialog open={confirmation} onClose={handleClose}>
                <Box style={{
                    padding: '8px 10px'
                }}>
                    <Typography style={{
                        fontWeight: 700
                    }}>Are you sure you want to log out ?</Typography>

                    <Box style={{
                        display: 'flex',
                        gap: 10,
                        marginTop: 15
                    }}>
                        <CancelBtn onClick={() => handleClose()} >Cancel</CancelBtn>
                        <ConfirmBtn onClick={() => handleConfirm()}>Confirm</ConfirmBtn>
                    </Box>
            
                </Box>

            </Dialog>

        </Component>
    )
}

export default Profile