import { Backdrop, Box,Button,CircularProgress,TextField,Typography,styled } from "@mui/material"
import back from '../../assets/Back.svg'
import { Link, useNavigate } from "react-router-dom" 
import { useEffect, useState } from "react"
import { signin, signup } from "../../services/api"
import { isMobileNumberOrEmail } from "./utils"
import { sendOtp } from "../../services/api"

const Component = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',

})
const LeftBx = styled(Box)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down("sm")]: {
        width: '0%'
    }
}))
const RightBx = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden',
    marginTop: 48,
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'flex-start',
        alignItems: 'flexStart',
    }
}))
const BackBtn = styled('button')({
    position: 'absolute',
    top: 12,
    left: 12
})
const Section = styled(Box)({
    margin: '6px 18px 0 18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
})
const Span = styled(Link)({
    color: '#24b9b0',
    textDecoration: 'none'
})


const VerifyBtn = styled('button')({
    width: 328,
    height: 48,
    borderRadius: 4,
    background: '#8d8c8c',
    color: '#fff',
    fontSize: 16,
    lineHeight: '23px'
})

const Input = styled(TextField)({
    width: 328,
    height: 58,
    borderRadius: 4,
    '& label.Mui-focused': {
        color: '#24b9b0',
    },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#24b9b0'
        }
    }
})

const ResendBtn = styled('button')({
    color: '#24b9b0',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '23px'

})
const Timer = styled(Typography)({
    padding: '0 10px',
    boxSizing: "border-box",
    fontSize: 16,
    fontWeight: 500
})




const Verification = ({ user }) => {


    const navigate = useNavigate()

    const [seconds, setSeconds] = useState(59)
    const [otp, setOtp] = useState("")
    const [loading, setLoading] = useState(false)

    let type = isMobileNumberOrEmail(user.username)

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prevSecond => prevSecond-1)
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const handleBack = () => {
        window.history.back()
    }

    const handleChangeOtp = (e) => {
        setOtp(e.target.value)
    }

    const handleVerifyOtp = async () => {
        setLoading(true)
        let response = await signup({
            name: user.name,
            username: type === "mobile" ? "+91"+user.username : user.username,
            otp: otp,
            password: user.password,
            appId:"com.iwayplus.navigation"
        })
        setLoading(false)

        console.log(response)
        if(response.status === 200) {
            setLoading(true)
            let response = await signin({
                username: user.username,
                password: user.password
            })
            setLoading(false)
            console.log(response)
            if(response.status === 200) {
                localStorage.setItem("accessToken", response.data.accessToken)
                localStorage.setItem("refreshToken", response.data.refreshToken)
                navigate('/')
            }
            else navigate('/auth/signin')
        }
        else alert("something went wrong")
    }
    const handleClickResend = async () => {
        let post = {
            name: user.name,
            password: user.password,
            username: type === "mobile" ? "+91"+user.username : user.username
        }

        setLoading(true)
        let response = await sendOtp(post)
        setLoading(false)

        if(response.status === 200) setSeconds(60)
        else {
            alert("something went wrong")
        }
    }
    return (
        <Component>
           <BackBtn onClick={() => handleBack()}><img src={back} /></BackBtn>
           <LeftBx>

            </LeftBx>
            <RightBx>
                <Section>
                    <Typography style={{
                        fontSize: 24,
                        fontWeight: 700,
                        lineHeight: '30px'
                    }} >Verify Your Account</Typography>
                    <Typography style={{
                        color: '#242424',
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: '25px',
                        marginTop: 8
                    }}>Please enter the verification code we’ve sent you on {isMobileNumberOrEmail(user.username) === "mobile" ? "+91"+user.username : user.username}<Span>Edit</Span></Typography>
                    <Input label="OTP" onChange={(e) => handleChangeOtp(e)} />
                    <Typography style={{
                        margin: "4px 0 0 16px",
                        fontSize: 12,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '16px'
                    }}>Enter your 6-digit otp here</Typography>
                    <Box style={{
                        width: 328,
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}>
                        {seconds>0 ? <Timer>00:{seconds}</Timer> : 
                            <ResendBtn onClick={() => handleClickResend()}>Resend OTP</ResendBtn>
                        }
                    </Box>
                    

                    <VerifyBtn onClick={() => handleVerifyOtp()} style={{
                        background: otp?.length === 6 ? '#24b9b0' : '#8d8c8c'
                    }}>Verify OTP</VerifyBtn>

                </Section>
                
            </RightBx>


            <Backdrop open={loading} >
                <CircularProgress  />
            </Backdrop>
        </Component>
    )
}

export default Verification