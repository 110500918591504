import { styled } from "@mui/material"

const Box = styled('div')({
    background: '#efefef',
    height: 15,
    borderRadius: 5,
    marginTop: 2,
    width: '50%'
})

const Shimmer = () => {
    return (
        <div style={{
            padding: 16
        }}>
            <Box style={{
                height: 20,
                width: '80%'
            }}></Box>
            <br />
            <Box></Box>
            <Box></Box>
            <Box></Box>
            <br />
            <br />
            <Box style={{
                 height: 20,
                 width: '80%'
            }}></Box>
            <br />
            <Box></Box>
            <Box></Box>
            <Box></Box>


        </div>
    )

}

export default Shimmer