import { Box, Typography } from "@mui/material"

import landmarkIcon from '../../assets/landmark icon.svg'

let baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOCAL_URL : process.env.REACT_APP_REMOTE_URL


const VenueCard = ({ data }) => {

    let address = data.address.split(',')
    let location = address[address.length-4]+","+address[address.length-3]
    return (
        <Box style={{
            display: "flex",
            gap: 16,
            border: '1px solid #ebebeb',
            borderRadius: 4
        }}>
            <img style={{
                width: 124
            }} src={`${baseUrl}/uploads/${data.venuePhoto}`} />
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}>
                <Box style={{
                    display: 'flex',
                    padding: '2px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 8,
                    borderRadius: 4,
                    marginBottom: 8,
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    background: "var(--Linear, linear-gradient(90deg, #0F98B5 -19.19%, rgba(135, 45, 225, 0.47) 115.38%))"
                }}>
                    <Typography style={{
                        color: '#fff',
                    }}>{data.venueCategory}</Typography>
                </Box>
                <Typography style={{
                    fontFamily: 'roboto',
                    fontSize: 16,
                    lineHeight: '25px',
                    fontWeight: 400,
                    textAlign: 'left'
                }}>{data.venueName}</Typography>
                <Box style={{
                    display: 'flex'
                }}>
                    
                    <img src={landmarkIcon} style={{
                        width: 16,
                        filter: 'invert(0.5)'
                    }} />
                    <Typography style={{
                        textAlign: 'left',
                        color: '#8d8d8d'
                    }}>{location}</Typography>
                </Box>

                    {data.distance && data.distance/1000 > 0.1 && <Typography style={{
                        color: '#34b9b0',
                        fontSize: 14,
                        lineHeight: '20px',
                        textAlign: 'left',
                        fontWeight: 400
                    }}>{(data.distance/1000).toFixed(1)} Km to Venue</Typography>}
                
            </Box>
        </Box>
    )
}

export default VenueCard