import { createSlice } from '@reduxjs/toolkit'

export const PatchSlice = createSlice({
  name: 'patch',
  initialState: {value: []},
  reducers: {
    setPatchDataReducer: (state, action) => {
      state.value = action.payload
    },
    addPatch: (state, action) => {
      if(state.value.filter(elm => elm.building_ID === action.payload.building_ID).length > 0) return
      state.value.push(action.payload)
    }
  }
})

export default PatchSlice.reducer
export const { setPatchDataReducer, addPatch } = PatchSlice.actions