import { Box, TextField, Typography, styled, InputAdornment, Backdrop, CircularProgress } from "@mui/material"
import { useState } from "react"
import { isMobileNumberOrEmail } from "./utils"
import { useNavigate } from "react-router-dom"
import { sendOtpForAccountVerify } from "../../services/api"

const Component = styled(Box)({
    overflow: 'hidden'
})
const Header = styled(Box)({
    height: 48,
    width: '100%'
})
const Heading = styled(Typography)({
    fontFamily: 'roboto',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '30px',
    color: '#000'
})
const SubHead = styled(Typography)({
    color: '#242424',
    fontFamily: 'roboto',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '25px'
})
const Input = styled(TextField)({
    marginTop: 20,
    width: 328,
    height: 56,
       '& label.Mui-focused': {
        color: '#24b9b0',
    },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#24b9b0'
        }
    }
})
const CountryBx = styled(Box)({
    position: 'absolute',
    top: '50%',
    marginLeft: 20,
    transform: 'translateY(-6%)'
})
const VerifyBtn = styled('button')({
    width: 328,
    height: 48,
    borderRadius: 4,
    background: '#24b9b0',
    color: '#fff',
    fontSize: 16,
    lineHeight: '23px',
    marginTop: 32
})
const LeftBx = styled(Box)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down("sm")]: {
        width: '0%'
    }
}))
const RightBx = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'flex-start',
        alignItems: 'flexStart',
    }
}))


const ForgotPassword = ({ setUsername, username }) => {

    const navigate = useNavigate()
    const [type, setType] = useState()
    const [loading, setLoading] = useState(false)
    

    const handleChange = (e) => {
        setUsername({...username, [e.target.name]: e.target.value})
        
        setType(isMobileNumberOrEmail(e.target.value))
    }

    const handleVerify = async () => {
        setLoading(true)
        let response = await sendOtpForAccountVerify({
            username: type==="mobile" ? "+91"+username.username : username.username
        })
        setLoading(false)
        if(response.status !== 200) {
            alert(response.data.message)
            return
        }
        navigate('/auth/reset-password-verification')
    }
    return (
        <Component>
            <Header>
                
            </Header>
            <LeftBx></LeftBx>
            <RightBx>
            <Box style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                margin: '6px 18px 0 18px'
            }}>
                <Heading>Forgot Password ?</Heading>
                <SubHead>If you’ve forgotten your password, don’t worry Enter your email or mobile number to verify.</SubHead>
            </Box>
            <Box style={{
                position: 'relative',
                width: 328,
                margin: '20px 16px 0 16px',
            }}>
                    <CountryBx style={{
                        position: 'absolute',
                        display: type==="mobile" ? 'block':'none'
                    }}>
                        <Typography>IN +91</Typography>
                    </CountryBx>
                    <Input InputProps={{
                    startAdornment: (
                        <>
                        {type === "mobile" && <InputAdornment 
                                position="start"
                                style={{
                                    marginLeft: "20%"
                                }}
                            />}
                        </>
                    )
                }} name="username" label="username or password" onChange={(e) => handleChange(e)} />
            </Box>

            <VerifyBtn alt="continue" onClick={() => handleVerify()}>Continue</VerifyBtn>


            </RightBx>

            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>


        </Component>
    )
}

export default ForgotPassword