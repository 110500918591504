import { Route, Routes } from "react-router-dom"
import Signup from "./Signup"
import Verification from "./Verification"
import Signin from "./Signin"
import { useState } from "react"
import ForgotPassword from "./ForgetPassword"
import ChangePassword from "./ChangePassword"
import ResetPasswordVerification from "./ResetPasswordVerification"
const initialValues = {
    username: "",
    name: "",
    password: "",
    confirmPassword: ""
}
const recovery = {
    username: "",
    newPassword: "",
    confirmPassword: "",
    otp: "",
    token: ""
}

const SignupRoute = () => {

    const [user, setUser] = useState(initialValues)
    const [username, setUsername] = useState(recovery)
    
    return (
        <Routes>
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<Signup setUser={setUser} user={user} />} />
            <Route path="verify" element={<Verification setUser={setUser} user={user} />} />

            <Route path="forgot-password" element={<ForgotPassword username={username} setUsername={setUsername}  />} />
            <Route path="reset-password" element={<ChangePassword username={username} setUsername={setUsername} />} />
            <Route path="reset-password-verification" element={<ResetPasswordVerification username={username} setUsername={setUsername} />} />

        </Routes>
    )
}

export default SignupRoute